const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    console.log(section, section)
    if (section) {
        const yOffset = -60; // Adjust based on your fixed header height
        const yPosition = section.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: yPosition, behavior: 'smooth' });
    }
};

export default scrollToSection;