import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Stack,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input/input';
// import { Form, Field, schemaHelper } from 'src/components/hook-form';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { PhoneInput } from "../../../components/phone-input";
import BOOK_FREE_TRIAL_BACKGROUND from '../../../assets/Event.svg';
import Image from '../../../components/Image';
import { dispatch, useSelector } from "../../../redux/store";
import { API_STATUS } from "../../../config";
import { login, signup, otpValidate, resetCheckOutData } from "../../../redux/slices/registration";
import SuccessErrorPopup from '../SuccessErrorPopup';

// const StyledContainer = styled(Container)(({ theme }) => ({
//   display: 'flex',
//   minHeight: '100vh',
//   padding: theme.spacing(8, 2),
// //   background: 'linear-gradient(135deg, #E3F2FD 0%, #BBDEFB 100%)',
// }));



const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 1200,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}));

const IllustrationBox = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'start',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));



const FormBox = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const RegistrationForm = ({ setActiveStep }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [ccpBeginnerSerialNumber, setCcpBeginnerSerialNumber] = useState('');
  const [mobileWithCode, setMobileWithCode] = useState('');
  const [mobile, setMobile] = useState('');
  const [dialCode, setDialCode] = useState('');
  const { otpGenerated, isLoading, referenceCode, OTPValidationAPIStatus } = useSelector((state) => state.registration);
  const [timer, setTimer] = useState(30);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupConfig, setPopupConfig] = useState({
    variant: 'success',
    title: '',
    message: '',
    buttonText: '',
    onButtonClick: () => { },
  });

  const handlemobileChange = (e) => {
    setMobileWithCode(e);
  };
  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleSerialNo = (e) => {
    setCcpBeginnerSerialNumber(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const responseData = await dispatch(otpValidate({
        payload: { eventId: "psm-ccp", mobile, otp, referenceCode, dialCode },
        enqueueSnackbar
      })).unwrap();
      if (responseData.data.redirectPath) {
        window.location.href = `${responseData.data.redirectUrl}`;
      } else {
        console.error("Redirect path is undefined");
      }
      setShowPopup(true);
    } catch (error) {
      setPopupConfig({
        variant: 'error',
        title: 'Verification Failed',
        message: 'Invalid OTP. Please try again.',
        buttonText: 'Try Again',
        onButtonClick: () => {
          setShowPopup(false);
          setOtp('');
        }
      });
      setShowPopup(true);
    }
  };

  const handleVerify = async () => {
    if (firstName.length && lastName.length) {
      try {
        await dispatch(signup({
          payload: { metadata: { ccpBeginnerSerialNumber }, firstName, lastName, mobile, dialCode, email },
          enqueueSnackbar
        })).unwrap();
      } catch (error) {
        setPopupConfig({
          variant: 'error',
          title: 'Failed to Send OTP',
          message: 'User already exists.',
          buttonText: 'Login',
          onButtonClick: () => {
            navigate(`/psm/event/e/psm-ccp/login`);
          }
        });
        setShowPopup(true);
      }
    }
  };

  let timerId;
  const startTimer = () => {
    timerId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(timerId);
          setIsResendEnabled(true);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };
  useEffect(() => {
    if (otpGenerated) {
      setIsOtpSent(true);
      setTimer(30);
      startTimer();
    }
  }, [otpGenerated]);


  const handleResendOTP = () => {
    setIsResendEnabled(false);
    setTimer(30);
    startTimer();
    enqueueSnackbar('OTP re-sent successfully', { variant: 'success' });
  };

  useEffect(() => {
    if (mobileWithCode) {
      const phoneNumber = parsePhoneNumber(mobileWithCode);
      if (phoneNumber) {
        setMobile(phoneNumber.nationalNumber);
        setDialCode(phoneNumber.countryCallingCode);
      }
    }
  }, [mobileWithCode]);

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  return (
    <>
      {showPopup && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1400,
          }}
        >
          <SuccessErrorPopup {...popupConfig} />
        </Box>
      )}

      <ContentWrapper>

        <FormBox>
          <Stack spacing={{ xs: 1.3, md: 2 }}>
            <>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, md: 2 }}>
                <TextField
                  fullWidth
                  label="First name"
                  variant="outlined"
                  value={firstName}
                  onChange={handleFirstName}
                />
                <TextField
                  fullWidth
                  label="Last name"
                  variant="outlined"
                  value={lastName}
                  onChange={handleLastName}
                />
              </Stack>
              <TextField
                fullWidth
                label="CCP Beginner Online Serial Number"
                variant="outlined"
                value={ccpBeginnerSerialNumber}
                onChange={handleSerialNo}
              />
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleEmail}
              />

              <Stack direction="row" spacing={2}>
                <PhoneInput
                  label="Mobile Number"
                  pt={1}
                  value={mobileWithCode}
                  onChange={handlemobileChange}
                  fullWidth
                  variant="outlined"
                  sx={{ marginBottom: '1.2rem' }}
                />
                {/* <Button
              onClick={handleVerify}
              >
                VERIFY
              </Button> */}
              </Stack>


              {isOtpSent && (
                <>
                  <TextField
                    fullWidth
                    type="number"
                    label="OTP"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleOtpChange}
                    variant="outlined"
                    placeholder="Enter 6-digit OTP"
                    inputProps={{
                      maxLength: 6,
                      pattern: '[0-9]*'
                    }}
                  />
                  <Box display="flex" justifyContent="space-between">
                    <Button variant="text" onClick={() => navigate(-1)}>
                      Back
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'text.secondary' }}>
                      <Typography>
                        {isResendEnabled ? 'Didn\'t receive the OTP ?' : `Resend OTP in ${timer}s`}
                        {isResendEnabled && <Link paddingLeft={1} sx={{
                          alignItems: 'center'
                        }} onClick={handleResendOTP} rel="noopener" variant="subtitle1" underline="always">
                          Resend OTP
                        </Link>}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}

              <Button
                fullWidth
                size="large"
                variant="contained"
                onClick={isOtpSent ? handleSubmit : handleVerify}
                sx={{
                  bgcolor: 'primary.dark',
                  color: 'white',
                  py: 1.5,
                  '&:hover': {
                    bgcolor: 'primary.darker',
                  },
                }}
                disabled={
                  !isValidPhoneNumber(mobileWithCode || '') ||
                  isLoading ||
                  OTPValidationAPIStatus === API_STATUS.LOADING ||
                  (isOtpSent && otp.length !== 6)
                }
              >
                {isOtpSent ? "Verify OTP" : "Send OTP"}
              </Button>
            </>
          </Stack>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}> Already have an account? <Link onClick={() =>
            // navigate(`${eventDetailsData?.organization?.id}/event/${eventDetailsData?.id}/login`
            navigate(`/psm/event/e/psm-ccp/login`
            )}
          >Login</Link></Typography>
        </FormBox>
      </ContentWrapper>
    </>
  );
};

export default RegistrationForm;
