import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { KRIDA_API } from '../../config';
import axios from '../../utils/axios'
import { otpValidate, login } from './registration'
import { updateCurrentOrgId } from './org';

const initialState = {
    user: {},
    players: [],
    currentOrgProfiles: [],
    currentPlayer: {},
    loader: false,
    currentOrgId: '',
    profileSelectedBySelf: false,
    loggedOut: false,
    referenceNumber: '',
    createProfileData: {},
}
const updateCurrentOrgHelper = (state, action) => {
    if (action.payload) {
        state.currentOrgId = action.payload
    } else {
        state.currentOrgId = ''
    }
    const currentOrgProfiles = state.players.filter((player) => player?.organizationId === state.currentOrgId)
    state.currentOrgProfiles = currentOrgProfiles
    state.currentPlayer = currentOrgProfiles.length > 0 ? currentOrgProfiles[0] : {}
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // resetData: () => initialState,
        // resetCheckOutData: (state) => {
        //     return { ...initialState }
        // }
        setReferenceNumber: (state, action) => {
            state.referenceNumber = action.payload
        },
        userLogout: (state) => {
            localStorage.clear()
            state = {
                user: {},
                players: [],
                currentOrgProfiles: [],
                currentPlayer: {},
                loader: false,
                currentOrgId: '',
                profileSelectedBySelf: false,
                loggedOut: false
            }
        },
        refreshCurrentOrgProfiles: (state, action) => {
            if (state.currentOrgId) {
                const currentOrgProfiles = state.players.filter((player) => player?.organizationId === state.currentOrgId)
                state.currentOrgProfiles = currentOrgProfiles
                state.currentPlayer = currentOrgProfiles.length > 0 ? currentOrgProfiles[0] : {}
            } else {
                state.currentOrgProfiles = []
                state.currentPlayer = {}
            }
        },
        updateUserData: (state, action) => {
            state.loader = false;
            const data = action?.payload?.data
            try {
                state.user = data
                state.players = data?.players || []
                if (state.currentOrgId) {
                    const currentOrgProfiles = data?.players?.filter((player) => player?.organizationId === state.currentOrgId) || [];
                    state.currentOrgProfiles = currentOrgProfiles;
                    state.currentPlayer = currentOrgProfiles.length > 0 ? currentOrgProfiles[0] : {}
                } else {
                    state.currentOrgProfiles = []
                }
            } catch (error) {
                // //   
            }
        },
        selectProfile: (state, action) => {
            state.loader = false;
            const { profileId, profileSelectedBySelf = true } = action.payload
            try {
                state.currentPlayer = state.currentOrgProfiles.find((player) => player?.id === profileId)
                state.profileSelectedBySelf = profileSelectedBySelf
            } catch (error) {
                // //   
            }
        }

    },
    extraReducers: (builder) => {
        builder.addCase(updateCurrentOrgId, updateCurrentOrgHelper);

        builder.addCase(fetchProfileData.pending, (state) => {
            state.loader = true;
            state.players = []
            state.user = {}
            state.currentOrgProfiles = []
        });
        builder.addCase(fetchProfileData.fulfilled, (state, action) => {
            state.loader = false;
            const data = action?.payload?.data
            state.eventData = data
            try {
                state.user = data
                state.players = data?.players || []
                if (state.currentOrgId) {
                    const currentOrgProfiles = data?.players?.filter((player) => player?.organizationId === state.currentOrgId) || [];
                    state.currentOrgProfiles = currentOrgProfiles
                    state.currentPlayer = currentOrgProfiles.length > 0 ? currentOrgProfiles[0] : {}

                } else {
                    state.currentOrgProfiles = []
                    state.currentPlayer = {}
                }
            } catch (error) {
                // //   
            }
        });
        builder.addCase(fetchProfileData.rejected, (state, data) => {
            state.loader = false;
            if (data?.payload?.error?.code === 401) {
                localStorage.removeItem('token')
                return { ...initialState }
            }
        });


        builder.addCase(createProfileForOrg.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(createProfileForOrg.fulfilled, (state, action) => {
            state.loader = false;

            const players = action?.payload?.data?.players || [];
            state.createProfileData = action?.payload?.data
            try {
                state.players = players;
                if (state.currentOrgId) {
                    const currentOrgProfiles = players?.filter((player) => player?.organizationId === state.currentOrgId) || [];
                    state.currentOrgProfiles = currentOrgProfiles
                    state.currentPlayer = currentOrgProfiles.length > 0 ? currentOrgProfiles[0] : {}

                } else {
                    state.currentOrgProfiles = []
                    state.currentPlayer = {}
                }
            } catch (error) {
                // //   
            }
        });
        builder.addCase(createProfileForOrg.rejected, (state, data) => {
            state.loader = false;
            state.error = {}
            if (data?.payload?.error?.code === 401) {
                localStorage.removeItem('token')
                return { ...initialState }
            }
        });

        builder.addCase(otpValidate.pending, (state) => {
            state.loader = true;
        });
        // builder.addCase(otpValidate.fulfilled, (state, action) => {
        //     state.loader = false;
        //     const data = action?.payload?.data
        //     state.playerVerifiedData = data
        //     const token = data?.token
        //     localStorage.setItem('token', token)
        // });
        builder.addCase(otpValidate.rejected, (state) => {
            state.loader = false;
        });
        builder.addCase(makePayment.pending, (state) => {
            state.registrationLoader = true;
        });
        builder.addCase(makePayment.fulfilled, (state, action) => {
            state.registrationLoader = false;
            const data = action?.payload?.data
            state.paymentData = data
        });
        builder.addCase(makePayment.rejected, (state, action) => {
            state.registrationLoader = false;
        });
        builder.addCase(addSubscription.pending, (state) => {
            state.registrationLoader = true;
            state.addSubscriptionLoader = true
        });
        builder.addCase(addSubscription.fulfilled, (state, action) => {
            state.registrationLoader = false;
            state.registeredData = action.payload;
            state.addSubscriptionLoader = false
        });
        builder.addCase(addSubscription.rejected, (state, action) => {
            state.registrationLoader = false;
            state.leadGenerated = false;
            state.addSubscriptionLoader = false
        });
    }
})

export const fetchProfileData = createAsyncThunk('profile', async (data, thunkApi) => {
    try {
        const token = localStorage.getItem('token')
        const { data: responseData } = await axios.get(`${KRIDA_API}user/kl/profile`,
            { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` } })
        return responseData
    } catch (e) {
        console.log(e)
        if (e === 'Unauthorized') {
            return thunkApi.rejectWithValue({
                error: {
                    code: 401,
                },
            })
        }
        return thunkApi.rejectWithValue(e)
    }
});

export const createProfileForOrg = createAsyncThunk('profileForOrg', async (data, thunkApi) => {
    try {
        const organizationId = thunkApi.getState().user.currentOrgId
        const token = localStorage.getItem('token')
        const { data: responseData } = await axios.post(`${KRIDA_API}user/kl/profile`, { organizationId, ...data },
            { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` } })
        thunkApi.dispatch(updateUserData(responseData))
        return responseData
    } catch (e) {
        console.log(e)
        if (e === 'Unauthorized') {
            return thunkApi.rejectWithValue({
                error: {
                    code: 401,
                },
            })
        }
        return thunkApi.rejectWithValue(e)
    }
});

export const makePayment = createAsyncThunk('makePayment', async (data, thunkApi) => {
    const { enqueueSnackbar, invoiceId, paymentMethod = 'non_upi' } = data
    try {
        const token = localStorage.getItem('token')
        const { data: responseData } = await axios.post(`${KRIDA_API}i/${invoiceId}/initiatepg`, { paymentMethod },
            {
                headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` }
            })
        return responseData
    } catch (e) {
        console.log(e)
        enqueueSnackbar('Something went wrong while making the payment', { variant: 'error' })
        return thunkApi.rejectWithValue(e)
    }
});

export const addSubscription = createAsyncThunk('addSubscription', async (data, thunkApi) => {
    const { enqueueSnackbar, payload } = data
    try {
        const token = localStorage.getItem('token')
        const { data: responseData } = await axios.post(`${KRIDA_API}s`, payload,
            { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` } })
        return responseData.data
    } catch (e) {
        console.log(e)
        enqueueSnackbar('Something went wrong while making the payment', { variant: 'error' })
        return thunkApi.rejectWithValue(e)
    }
});

export const { handleAcceptanceForm, selectedEventsAction, resetData, resetCheckOutData,
    refreshCurrentOrgProfiles, updateUserData, selectProfile, userLogout, setReferenceNumber } = userSlice.actions

export default userSlice.reducer;