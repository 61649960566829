import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, Divider, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import scrollToSection from '../../utils/scrollToSection';
import useResponsive from '../../hooks/useResponsive';



const ClubHeroCta = ({ ctaList = [] }) => {
  const primaryCta = ctaList.find(cta => cta.primary);
  const secondaryCta = ctaList.find(cta => !cta.primary);
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  let primaryQueryParams = '';
  if (primaryCta?.query) {
    const searchParams = new URLSearchParams(primaryCta.query);
    primaryQueryParams = searchParams.toString();
  }

  return (primaryCta && <Stack direction="row" spacing={2} sx={{
    width: "100%"
  }}>
    <Button sx={{
      lineHeight: 1.5,
      backgroundColor: '#ffffff',
      color: '#081F40',
      borderColor: '#cccccc',
      '&:hover': {
        backgroundColor: '#e3f2fd',
        borderColor: '#b6e3ff',
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
      },
      '&:active': {
        backgroundColor: '#bbdefb',
        borderColor: '#90caf9',
        boxShadow: 'none',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,0.5)',
      },
    }} variant='contained' size='large'
      fullWidth
      onClick={() => {
        navigate(`/${primaryCta.url}?${primaryQueryParams}`);
      }
      }>{primaryCta?.text}
    </Button>

    {(mdUp && secondaryCta) && <Button sx={{
      backgroundColor: '#193052', color: '#fff'
    }}
      variant='outlined'
      onClick={() => {
        if (secondaryCta?.id === 'contactUs') scrollToSection('contactUs')
      }}>{secondaryCta.text}
    </Button>}
  </Stack>
  );
};

ClubHeroCta.propTypes = {
  ctaList: PropTypes.array,
};

export default ClubHeroCta;
