import React, { forwardRef } from 'react'
import {
  Box,
  Button,
  Grid,
  Card,
  Typography,
  Stack,
  Avatar,
} from '@mui/material';
import Iconify from '../../../components/Iconify';

import DynamicAspectRatioImage from '../../../components/DynamicAspectRatioImage';

// Helper function to determine the appropriate icon based on the heading
const getIcon = (heading) => {
  switch (heading) {
    case 'Time':
      return 'solar:clock-circle-bold';
    case 'Location':
      return 'logos:google-maps';
    default:
      return 'solar:calendar-bold';
  }
};

const About = forwardRef(({ about }, ref) => (
  <Card
    ref={ref}
    sx={{
      m: { xs: 0, md: 2 },
      px: { xs: 2, md: 8 },
      py: 4,
      bgcolor: 'white',
      mb: { xs: 3 },
    }}
  >
    {/* Organizer Section */}
    <Card sx={{ p: 2, bgcolor: 'white', boxShadow: 'none', mb: 3 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        {about?.data?.logo ? (
          <DynamicAspectRatioImage size={56} uri={about?.data?.logo} />
        ) : (
          <Avatar
            sx={{
              width: 56,
              height: 56,
              bgcolor: 'primary.main',
              fontSize: '1.5rem',
            }}
          >
            {about?.data?.name?.charAt(0)}
          </Avatar>
        )}
        <Box>
          <Typography variant="subtitle2" color="text.secondary">
            Organized by
          </Typography>
          <Typography variant="h6" color="text.primary" fontWeight={600}>
            {about?.data?.name}
          </Typography>
        </Box>
      </Stack>
    </Card>

    {/* Event Details and Contact Grid */}
    <Grid container spacing={4}>
      {/* Event Details */}
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          {about?.data?.eventDetails?.map((item) => (
            <Stack key={item.id} direction="row" spacing={2}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  pt: 0.5,
                }}
              >
                <Iconify
                  icon={item.icon || getIcon(item.heading)}
                  sx={{ color: 'primary.main', width: 24, height: 24 }}
                />
              </Box>
              <Box>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  {item.label}
                </Typography>
                <Typography variant="body1" color="text.primary">
                  {item.value}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Stack>
      </Grid>

      {/* Contact Details */}
      <Grid item xs={12} md={4}>
        <Box>
          <Typography variant="h6" color="text.primary" gutterBottom>
            Contact Details
          </Typography>
          <Stack spacing={2}>
            <Stack 
              direction="row" 
              spacing={2} 
              alignItems="center"
              component="a"
              href={`tel:${about?.data?.contactDetails?.phone}`}
              sx={{ 
                cursor: 'pointer',
                textDecoration: 'none',
                '&:hover': { opacity: 0.8 }
              }}
            >
              <Iconify 
                icon="solar:phone-bold" 
                sx={{ 
                  color: 'primary.main', 
                  width: 20, 
                  height: 20,
                  flexShrink: 0 
                }} 
              />
              <Typography 
                variant="body2" 
                color="text.primary"
                sx={{ wordBreak: 'break-all' }}
              >
                {about?.data?.contactDetails?.phone}
              </Typography>
            </Stack>
            <Stack 
              direction="row" 
              spacing={2} 
              alignItems="center"
              component="a"
              href={`mailto:${about?.data?.contactDetails?.email}`}
              sx={{ 
                cursor: 'pointer',
                textDecoration: 'none',
                '&:hover': { opacity: 0.8 }
              }}
            >
              <Iconify 
                icon="solar:letter-bold" 
                sx={{ 
                  color: 'primary.main', 
                  width: 20, 
                  height: 20,
                  flexShrink: 0 
                }} 
              />
              <Typography 
                variant="body2" 
                color="text.primary"
                sx={{ wordBreak: 'break-all' }}
              >
                {about?.data?.contactDetails?.email}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  </Card>
));

export default About