import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Modal, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// routes
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { dispatch } from '../../../redux/store';
import { userLogout, selectProfile, createProfileForOrg } from '../../../redux/slices/user';
import { resetCart } from '../../../redux/slices/product';
import useResponsive from '../../../hooks/useResponsive';
import { RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: '',
  },
  // {
  //   label: 'Settings',
  //   linkTo: PATH_DASHBOARD?.user?.account,
  // },
];

// ----------------------------------------------------------------------

const AccountPopover = () => {
  const navigate = useNavigate();
  const smUp = useResponsive('up', 'sm');
  const smDown = useResponsive('down', 'sm');

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);
  const [name, setName] = useState('');
  const [openAddProfileModal, setOpenAddProfileModal] = useState(false)
  const [currentData, setCurrentData] = useState({})
  const [openProfiles, setOpenProfiles] = useState(true)
  const { currentPlayer, currentOrgProfiles, loader, createProfileData } = useSelector((state) => state.user)

  useEffect(() => {
    setCurrentData(currentPlayer)
  }, [currentPlayer])

  useEffect(() => {
    if (createProfileData && Object.keys(createProfileData)?.length) {
      setOpenAddProfileModal(false)
      const player = findLatestObjectByTimestamp(createProfileData?.players || [])
      dispatch(selectProfile({ profileId: player?.id }))
    }
  }, [createProfileData])

  const findLatestObjectByTimestamp = (array) => {
    return array.reduce((latestObject, currentObject) => {
      const latestTimestamp = latestObject
        ? new Date(latestObject.createdAt)
        : new Date(0);
      const currentTimestamp = new Date(currentObject.createdAt);
  
      return currentTimestamp > latestTimestamp ? currentObject : latestObject;
    }, null);
  }
  
  const handleOpen = (event) => {
    if (Object.keys(currentData).length) setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
    // setOpenProfiles(false);
  };

  const handleLogout = async () => {
    try {
      dispatch(userLogout())
      setCurrentData({})
      dispatch(resetCart());
      navigate(0)
      // await logout();
      // navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }

    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const navigateToProfile = () => {
    setOpenProfiles(!openProfiles)
    // navigate(PATH_AUTH.profile);
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar user={currentData} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentPlayer?.firstName} {currentPlayer?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {currentPlayer?.mobile}
          </Typography>
        </Box>

        {/* <Divider sx={{ borderStyle: 'dashed' }} />

        {Object.keys(currentPlayer).length && currentPlayer?.firstName && <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>} */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        {currentPlayer && Object.keys(currentPlayer).length && currentPlayer?.firstName &&
          <>
            <MenuItem onClick={navigateToProfile} sx={{ m: 1 }}>
              My Profile
            </MenuItem>
            {openProfiles &&
              <>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {currentOrgProfiles?.map((item) => {
                  return (
                    <MenuItem onClick={() => {
                      dispatch(selectProfile({ profileId: item?.id }))
                    }}>
                      {currentPlayer?.id !== item?.id ?
                        <span style={{ marginLeft: '8px', marginRight: '8px' }}>-</span>
                        : <CheckCircleIcon sx={{
                          fontSize: 24,
                          borderColor: 'green',
                          color: 'green',
                          marginLeft: '8px', marginRight: '8px',
                        }} />}
                      {item?.firstName} {item?.lastName}
                    </MenuItem>
                  )
                })}
              </>
            }
            {
              openProfiles &&
              <>
                <MenuItem onClick={() => {
                  setOpenAddProfileModal(true)
                }} sx={{ mx: 1 }}>

                  <AddCircleOutlineIcon
                    sx={{
                      fontSize: 24,
                      borderColor: 'green',
                      color: 'green',
                      marginLeft: '8px', marginRight: '8px',
                    }}
                  />
                  Add new profile
                </MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </>
            }

            <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
              Logout
            </MenuItem>
          </>
        }
      </MenuPopover>
      <Modal open={openAddProfileModal} onClose={() => setOpenAddProfileModal(false)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#000",
            width: smUp ? "420px" : "360px",
            height: smDown ? "50%" : "70%",
            minHeight: "200px",
            mx: "auto",
            my: smDown ? "24vh" : "10vh",
            p: "2rem",
            borderRadius: "4px",
            position: "relative",
            color: "#fff",
          }}
        >
          <h2 style={{ color: "#fff", marginBottom: "1rem" }}>Add New Profile</h2>
          <MyAvatar
            alt="Avatar"
            src="https://m.media-amazon.com/images/G/02/CerberusPrimeVideo-FN38FSBD/adult-3.png"
            sx={{ width: "80px", height: "80px", marginBottom: "1rem" }}
          />
          <TextField
            fullWidth
            name="displayName"
            label="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            InputProps={{
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "#fff",
                padding: "0.5rem 1rem",
                "&::placeholder": {
                  color: "rgba(255, 255, 255, 0.8)",
                  padding: "0.5rem",
                },
              },
            }}
            sx={{ border: "none", borderRadius: "4px", marginBottom: "1rem" }}
          />
          <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
            {/* <RHFTextField name="about" multiline rows={4} label="About" /> */}
            <LoadingButton variant="contained" loading={loader} onClick={() => {
              const data = {
                addDuplicatePlayer: true,
                firstName: name?.split(' ')[0],
                lastName: name?.split(' ')[1]
              }
              dispatch(createProfileForOrg(data))
            }}>
              Save Changes
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

    </>
  );
};

export default AccountPopover;
