import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Grid,
  Container,
  Typography,
  Card,
  Stack,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { styled } from '@mui/system';
import MainFooter from '../../../layouts/main/MainFooter';
import Image from '../../../components/Image';
import useResponsive from '../../../hooks/useResponsive';
import EVENT_DETAIL from '../../../assets/eventDetail.webp';

import { fetchOrgData } from '../../../redux/slices/org';
import { fetchEventDetails, fetchSimilarEventList } from '../../../redux/slices/event';
import MainLayout from '../../../layouts/main';
import ClubHeroCta from '../../../krida/cta/ClubHeroCta';
import SportLabel from '../../../krida/label/SportLabel';
import DynamicAspectRatioImage from '../../../components/DynamicAspectRatioImage';
import CarouselV2 from '../../../krida/carousel/carousel-v2';
import KridaLabel from '../../../krida/label/KridaLabel';
import Iconify from '../../../components/Iconify';
import BOOK_FREE_TRIAL_BACKGROUND from '../../../assets/Event.svg';
import ComponentBlock from '../../../krida/ComponentBlock';
import { API_STATUS } from '../../../config';
import LoadingScreen from '../../../components/LoadingScreen';
import EventCard from '../../../krida/event/EventCard';

const useStyles = styled((theme) => ({
  landingContainer: {
    padding: theme.spacing(8),
    background: 'linear-gradient(115deg, #000101 0%, #00204F 100%)',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      background: 'linear-gradient(115deg, #000101 0%, #00204F 100%)',
      padding: theme.spacing(0),
    },
  },
  eventDetailsContainer: {
    padding: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  centerContainer: {
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    // backgroundColor: '#193052'
  },
  trainerContainer: {
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    // backgroundColor: '#193052'
  },
  photoGalleryContainer: {
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(4),
    },
  },
  sportsTabContainer: {
    marginTop: theme.spacing(4),
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    marginLeft: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
  },
  tabContainer: {
    marginTop: theme.spacing(4),
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      marginTop: theme.spacing(1),
    },
  },
  plansContainer: {
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  offeringsContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    backgroundColor: '#193052',
    padding: theme.spacing(8),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  eventContainer: {
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(4),
    },
  },
  contactUsContainer: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    backgroundColor: '#193052',
    height: '256px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
  },
}));
const RegistrationBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // justifyContent: 'space-between',
  backgroundColor: '#E7F7ED',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
const Hero = ({ config }) => {
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { orgDataLoader, orgData } = useSelector((state) => state.org);
  const { eventListLoader, similarEventData, eventDetailsLoader, eventListData, eventDetailsData } = useSelector(
    (state) => state.event
  );
  const navigate = useNavigate();
  const [organisationData, setOrganisationData] = useState([]);
  const [centers, setCenters] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const smDown = useResponsive('down', 'sm');
  const mdUp = useResponsive('up', 'md');

  const classes = useStyles();
  const { orgId, eventId, clubId } = useParams();
  const [value, setValue] = useState(0);
  const [accordianData, setAccordianData] = useState([]);
  const [controlled, setControlled] = useState(false);
  const [pageTitle, setPageTitle] = useState('KRIDA | Play, Train & Compete');

  const handleChangeControlled = (panel) => (event, isExpanded) => {
    setControlled(isExpanded ? panel : false);
  };
  const heroDetails = config?.data;

  // useEffect(() => {
  //     dispatch(fetchEventDetails({ eventId, clubId }))
  // }, []);

  // useEffect(() => {
  //     const acc = []
  //     if (eventDetailsData?.id) {
  //         if (eventDetailsData?.metadata?.features) {
  //             acc.push({
  //                 id: 'feature',
  //                 value: 'feature',
  //                 heading: 'Key Features',
  //                 list: eventDetailsData?.metadata?.features
  //             })
  //             setControlled('feature')
  //         }
  //         if (eventDetailsData?.metadata?.tnc) {
  //             acc.push({
  //                 id: 'tnc',
  //                 value: 'tnc',
  //                 heading: 'Terms & Conditions',
  //                 list: eventDetailsData?.metadata?.tnc
  //             })
  //         }
  //         setAccordianData(acc);
  //         if (eventDetailsData?.organization?.id) {
  //             dispatch(fetchSimilarEventList({ orgId: eventDetailsData?.organization?.id }));
  //             setPageTitle(`${eventDetailsData.organization.name} | Summer Camp 2024`)
  //         }
  //     }
  // }, [eventDetailsData]);
  if (eventDetailsLoader === API_STATUS.LOADING) {
    return <LoadingScreen />;
  }

  return (

    <Box sx={{ mt: 4, px: { md: 8 }, py: 2 }}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { md: 'center' },
            }}
          >
            <Box sx={{ mb: 4, display: 'flex', alignItems: { xs: 'center', md: 'start' }, flexDirection: 'column', gap: 2 }}>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                {heroDetails?.logo && <DynamicAspectRatioImage uri={heroDetails?.logo} />}
                <Typography variant='h3' color="black" >
                  {heroDetails?.name}
                </Typography>
              </Stack>
              <Typography color="text.secondary" variant='body1' sx={{ mb: 2, px: 2 }}>
                {heroDetails?.description}
              </Typography>

              {/* 
                            onClick={() => {
                                navigate(
                                    `/${eventDetailsData?.organization?.id}/event/${eventDetailsData?.id}?city=${eventDetailsData?.clubs?.[0]?.city}&club=${eventDetailsData?.clubs?.[0]?.id}/register`
                     */}

              {heroDetails?.cta.isVisible &&
                heroDetails?.cta?.buttons?.map((button) => (
                  <Button
                    key={button.id}
                    variant={button.primary ? 'contained' : 'outlined'}
                    size="large"
                    disabled={currentUser}
                    fullWidth
                    sx={{
                      bgcolor: button.primary ? '#00A76F' : '#fff',
                      color: button.primary ? '#FFFFF' : '#00A76F',
                      py: 1.5,
                      fontSize: { xs: '0.9rem', md: '1.1rem' },
                      fontWeight: 600,
                      maxWidth: '300px',
                      mb: 2,
                    }}
                    onClick={() => {
                      navigate(`${button.url}`);
                    }}
                  >
                    {currentUser ? 'Registered' : button.label}
                  </Button>
                ))}

              {currentUser?.id && (
                <RegistrationBanner>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CheckCircleIcon sx={{ color: '#00A76F' }} />
                    {/* <Typography sx={{ color: '#00A76F', fontWeight: 500 }}>
                    You're registered for this program
                  </Typography> */}
                  </Box>
                  <Typography
                    sx={{ color: '#00A76F', px: 1.5, py: 0.5, borderRadius: 1 }}
                  //  bgcolor: '#D1F0E1'
                  >
                    Registered ID: {currentUser?.id}
                  </Typography>
                </RegistrationBanner>
              )}
            </Box>

          </Box>
        </Grid>

        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Box mb={mdUp ? 0 : 3}>
            <CarouselV2
              data={
                heroDetails?.images?.map((img) => ({
                  url: img.url,
                  alt: img.alt,
                })) || [
                  {
                    url: BOOK_FREE_TRIAL_BACKGROUND,
                  },
                ]
              }
              containerSx={{
                '& .slick-list': {
                  borderRadius: '16px',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
};

export default Hero;
