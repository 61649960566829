import React from 'react';
import { Box, Typography, Button, Stack, Grid, Card, CardMedia } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import BOOK_FREE_TRIAL_BACKGROUND from '../../assets/Event.svg';

import useResponsive from '../../hooks/useResponsive';
import Iconify from '../../components/Iconify';
import SportLabel from '../label/SportLabel';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '340px',
  marginBottom: theme.spacing(2),
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 180,
}));

const EventCard = ({ item }) => {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');

  return (
    <StyledCard>
      <StyledCardMedia image={BOOK_FREE_TRIAL_BACKGROUND} />
      <Grid item sx={{ p: 2 }}>
        <Box>
          <Stack justifyContent={'flex-start'} flexDirection={'row'} flexWrap={'wrap'}>
            <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{item?.orgName}</Typography>
          </Stack>
          <Typography sx={{ lineHeight: 1.2 }} fontWeight={'400'} variant='h5'>{item?.eventName}</Typography>
          <Stack my={1}>
            <SportLabel isId data={item?.sportsId || []} />
          </Stack>
          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Stack flexDirection={'row'}>
              <Iconify color={'#00A76F'} icon={'fluent-emoji:spiral-calendar'} />
              <Typography pl={1} variant={!mdUp ? "body2" : "subtitle2"} sx={{ fontWeight: '500' }} align={"start"}>
                {moment.unix(item?.startDate).format('Do MMM yy')} - {moment.unix(item?.endDate).format('Do MMM yy')}
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection={'row'} marginBottom={'2px'} alignItems={'center'}>
            <Iconify color={'#00A76F'} icon={'logos:google-maps'} />
            <Typography pl={1} variant={!mdUp ? "body2" : "subtitle2"} sx={{ textTransform: 'capitalize' }} align={"start"}>
              {item?.club?.address?.fullAddress}
            </Typography>
          </Stack>
        </Box>
        <Stack width={'100%'} mt={1} justifyContent={'flex-end'}>
          <Button
            fullWidth
            sx={{
              backgroundColor: '#193052',
              marginY: 1,
              fontSize: 'small',
            }}
            variant="contained"
            onClick={() => {
              navigate(`/${item?.orgId}/event/${item?.eventId}?city=${item?.club?.city}&club=${item?.club?.id}`);
            }}
          >
            Register Now
          </Button>
          <Button
            fullWidth
            sx={{
              fontSize: 'small',
              width: '100%'
            }}
            onClick={() => {
              navigate(`/event/${item?.clubId}/${item?.eventId}`);
            }}
            variant="outlined"
          >
            View Details
          </Button>
        </Stack>
      </Grid>
    </StyledCard>
  );
};

export default EventCard;