// routes
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback';

// ----------------------------------------------------------------------

export const App = () =>
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <NotistackProvider>
            <MotionLazyContainer>
              <ProgressBarStyle />
              <ChartStyle />
              {/* <Settings /> */}
              <ScrollToTop />
              <LocalizationProvider dateAdapter={AdapterMoment} >
              <Router />
              </LocalizationProvider>
            </MotionLazyContainer>
          </NotistackProvider>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  </ErrorBoundary>;
