import React, { useState } from 'react';
import { Card, Tabs, Tab } from '@mui/material';

const TabsValue = ({ refs,tabs }) => {
    const [tabValue, setTabValue] = useState('');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (refs && refs[newValue] && refs[newValue].current) {
            refs[newValue].current.scrollIntoView({ 
                behavior: 'smooth',
            });
        }
    };

    return (
        <Card             sx={{ mx: {xs: 0, md:4}, px: { md: 4 }, py: {xs:1,md:3}, bgcolor: 'white' ,mb:{xs:3},mt:{md:3}}}
>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
            >
                {tabs?.map((tab) => (
                    <Tab 
                        key={tab.value}
                        value={tab.value}
                        label={tab.label}
                    />
                ))}
            </Tabs>
        </Card>
    );
};

export default TabsValue;
