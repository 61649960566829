import { useState,useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Stack,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input/input';
// import { Form, Field, schemaHelper } from 'src/components/hook-form';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { PhoneInput } from "../../../components/phone-input";
import BOOK_FREE_TRIAL_BACKGROUND from '../../../assets/Event.svg';
import Image from '../../../components/Image';
import { dispatch, useSelector } from "../../../redux/store";
import { API_STATUS } from "../../../config";
import { login, signup, otpValidate, resetCheckOutData } from "../../../redux/slices/registration";
import SuccessErrorPopup from '../SuccessErrorPopup';
import RegistrationForm from './RegistrationForm';
import VerifyForm from './VerifyForm'
import Success from './Success';
import MainHeader from '../../../layouts/main/MainHeader';


const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  padding: theme.spacing(8, 2),
//   background: 'linear-gradient(135deg, #e3f2fd 0%, #bbdefb 100%)',
}));



const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 1200,
  margin:{ sx: '0',md:'auto'},
  gap: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}));

const IllustrationBox = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'start',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));



const FormBox = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const RegisterEvent = () => {
  const [activeStep, setActiveStep] = useState(2); 
 


  return (
    <>
    <MainHeader />
    <StyledContainer>
      <ContentWrapper>
        <IllustrationBox>
          <Box>
           <Image src={ BOOK_FREE_TRIAL_BACKGROUND} alt={"bg"} />
          </Box>
        </IllustrationBox>

        <FormBox>
            <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 }, flexDirection: 'column', mt: 2 }}>
            <Typography variant="h3">
          Registration        
    </Typography>
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            DEC 2024 CCP Beginner practical on-court program.
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            Please fill in your details
            </Typography>
          </Box>
 
      <RegistrationForm setActiveStep={setActiveStep} />
    
 
        </FormBox>
      </ContentWrapper>
    </StyledContainer>
    </>
  );
};

export default RegisterEvent;
